<template>
    <div class="order-list" v-loading="bxLoading">
        <div class="filter-container" :span="24">
            <template>
                <el-input v-model="listQuery.order_sn" placeholder="订单号" style="width: 200px;" class="filter-item"
                          @keyup.enter.native="handleFilter"><i class="el-icon-error"></i></el-input>
            </template>

            <el-button :loading="loading" class="filter-item" size="small" type="primary" icon="el-icon-search"
                       @click="handleFilter">
                搜索
            </el-button>
            <el-button class="filter-item" type="warning" size="small" @click="filter1011">
                等待检测(1011)
            </el-button>
            <el-button class="filter-item" type="warning" size="small" @click="resetFilter">
                重置
            </el-button>
        </div>
        <el-container>

            <div class="tables" >
                <el-table
                        :data="ordersListFilter"
                        stripe
                        size="small"
                        ref="multipleTable"
                        tooltip-effect="dark"
                        @selection-change="handleSelectionChange"
                        :default-sort="{prop: 'id', order: 'descending'}"
                        >
                    <el-table-column
                            label="ID"
                            prop="id">
                    </el-table-column>
                    <el-table-column
                            width="110"
                            label="系统类型"
                            prop="paper.name"
                    >
                    </el-table-column>
                    <el-table-column
                            label="订单号"
                            prop="order_sn"
                            sortable
                            width="180">
                    </el-table-column>

                    <el-table-column
                            prop="author"
                            label="作者"
                            width="85"
                            :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="publish_time"
                            label="发表日期"
                            width="105"
                    >
                    </el-table-column>
                    <el-table-column
                            width="120"
                            label="字数/金额"
                    >
                        <template slot-scope="scope">
                            {{scope.row.words}} / {{scope.row.total_fee}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="支付方式"
                    >
                        <template slot-scope="scope">
                            <div v-if="scope.row.log !== null " style="text-align: center">
                                <span v-if="scope.row.log"><img :src="payTypeIcon(scope.row.log.pay_type)"
                                                                alt=""></span>
                            </div>
                            <div v-else>null</div>
                        </template>
                    </el-table-column>

                    <el-table-column
                            label="状态"
                            style="text-overflow: inherit;"
                    >
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" :content="scope.row.fail_content || ''"
                                        placement="top-start" v-if="scope.row.fail_content">
                                <el-tag size="small" :type="scope.row.statuses.type">{{scope.row.statuses.string}}</el-tag>
                            </el-tooltip>
                            <el-tag
                                    v-else
                                    size="small" :type="scope.row.statuses.type">{{scope.row.statuses.string}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="利润"
                    >
                        <template slot-scope="scope">
                            {{ (scope.row.total_fee - (scope.row.paper.cost_money * (Math.ceil(scope.row.words / scope.row.paper.cost_num)))).toFixed(2)}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="ip"
                            width="105"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.log.log_form.ip }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="成本"
                    >
                        <template slot-scope="scope">
                            {{ (scope.row.paper.cost_money * (Math.ceil(scope.row.words / scope.row.paper.cost_num))).toFixed(2) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="created_at"
                            sortable

                            label="提交时间"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            width="385">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    @click="handleEdit(scope.$index, scope.row)">编辑
                            </el-button>
                            <el-button-group style="margin-left: 10px;">
                                <inter-face-button
                                        :status="scope.row.status"
                                        :oid="scope.row.id"
                                        :group_interface_info="scope.row.paper.paper_group.group_interface_info"
                                        :interfaceOrder="scope.row.interface_order">
                                </inter-face-button>
                                <el-button type="info" size="mini" @click="handleEdit(scope.$index, scope.row)"
                                           :disabled="scope.row.interface_order !== null">人工检测
                                </el-button>
                            </el-button-group>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    :disabled="scope.row.status === 2 || scope.row.status === -1"
                                    @click="handleSetFail(scope.$index, scope.row)"
                                    slot="reference">失败?
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    slot="reference">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-container>
        <div class="block">
            <pagination
                    :hiddenSingle="false"
                    :meta="meta"
                    :layout="'prev, pager, next, jumper'"
                    v-on:pagination:switched="handleSwitchPage"
            />
        </div>
        <edit
                :dialogFormVisible="dialogFormVisible"
                :form="form"
                :index="index"
                :status="status"
                :action="action"
                v-on:cancle-dialog="handleCancleDiaLog"
                @removeReport="handleRemoveReport"
                @setStatus="handleSetStatus"
                :loading="loading"/>
        <el-dialog title="设置失败" :visible.sync="failDialog">
            <el-form :model="form">
                <el-form-item label="失败原因">
                    <el-input v-model="fail.failContent" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="failDialog = false">取 消</el-button>
                <el-button type="primary" @click="updateToFail">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {getOrderList, deleteOrder, setOrderToFail} from '@/api/order'
    import edit from "./edit";
    import interFaceButton from "./interfaceButton";
    import Pagination from "../../components/pagination/Pagination";
    import {mapGetters} from 'vuex'

    export default {
        name: "orderlist",
        data() {
            return {
                meta: [],
                listQuery: {
                    'order_sn': null
                },
                filterStatus: null,
                action: process.env.VUE_APP_BASE_API + '/admin/orders/upload/report',
                ordersList: [],
                currentPage: 1,
                bxLoading: true,
                loading: false,
                pagesize: 3,
                total: 0,
                multipleSelection: [],
                dialogFormVisible: false,
                form: null,
                status: [
                    {value: 0, status: '未支付'},
                    {value: 1, status: '等待检测'},
                    {value: 2, status: '已完成'},
                    {value: 9, status: '已删除'},
                    {value: -1, status: '检测失败'},
                ],
                index: null, //正在编辑的index
                msg: {
                    'm9': '此操作将永久删除, 是否继续?(包括相关订单信息)',
                    'm2': '此操作将删除此订单',
                    'm1': '确定要设置为检测失败吗？',
                    'm-1': '已经是检测失败状态,要更改失败内容吗？',
                },
                failDialog: false,
                fail: {
                    id: null,
                    failContent: '',
                }
            }
        },
        created() {
        },
        watch: {
            '$route': function (res) {
                // console.log(res)
                if (res.params.order) {
                    this.form = res.params.order;
                    this.dialogFormVisible = true;
                }
            },
            // eslint-disable-next-line no-unused-vars
            orders(res) {
                //
            }
        },
        computed: {
            ...mapGetters({
                orders: 'order/orders',
            }),
            
            ordersListFilter() {
                return this.ordersList
            }
        },
        mounted() {
            if (this.$route.params.order) {
                this.form = this.$route.params.order;
                this.dialogFormVisible = true;
            }
            this.getOrderList()
            this.QueryStatusSuccess();
            this.NewOrdernotifying();
            // setInterval(() => {
            //     this.getOrderList()
            // }, 5000)
        },
        methods: {
            filter1011() {
                this.filterStatus = 1011
                this.getOrderList()
            },
            resetFilter() {
                this.filterStatus = null
                this.getOrderList()
            },
            NewOrdernotifying(){
                window.Echo.private('new.orders')
                // eslint-disable-next-line no-unused-vars
                    .listen('NewOrdernotifying', (rsp) => {
                        console.log(rsp)
                        // this.bxLoading = true
                        // this.getOrderList(this.meta.current_page)
                        
                        let orderIndex = this.ordersList.findIndex((item) => {
                            return item.id === rsp.order.id
                        });
                        console.log(orderIndex, this.ordersList[orderIndex])
                        if(this.ordersList[orderIndex]) {
                            this.ordersList[orderIndex].status = rsp.order.status
                        }
                        if(orderIndex === -1) {
                            this.ordersList.unshift(rsp.order)
                        }
                    })
            },
            QueryStatusSuccess(){
                if (window.Echo !== undefined) {
                    window.Echo.private('query.interface.status')
                        .listen('QueryStatusSuccess', (rsp) => {
                            console.log(rsp)
                            let orderIndex = this.ordersList.findIndex((item) => {
                                return item.id === rsp.order.paper_order.id
                            });
                            console.log(this.ordersList[orderIndex]);
                            this.ordersList[orderIndex].interface_order  = rsp.order
                            this.ordersList[orderIndex].status = rsp.order.paper_order.status
                            this.ordersList[orderIndex].statuses = rsp.order.paper_order.statuses
                            // this.ordersList[orderIndex].interface_order = rsp.order
                            console.log(rsp.order)
                        })
                }
            },
            payTypeIcon(type) {
                if (type === '支付宝') {
                    return '/images/pay/pay_16_3.png';
                }
                if (type === '微信') {
                    return '/images/pay/pay_16_2.png';
                }
            },
            //上传更新ui
            handleSetStatus(index, row) {
                const newItem = this.ordersList.filter(value => value.id === row.id)[0];
                newItem.status = 2;
                newItem.statuses = {'type': 'success', 'string': '检测成功'};
                this.form[index] = newItem
            },
            //删除报告更新ui
            // eslint-disable-next-line no-unused-vars
            handleRemoveReport(item) {
                const newItem = this.ordersList.filter(value => value.id === item.id)[0];
                newItem.status = 2;
                newItem.statuses = {'type': 'warning', 'string': '等待检测'};
                this.form = newItem;
                this.form.report = null;
            },
            //设置隐藏模态框
            handleCancleDiaLog() {
                this.dialogFormVisible = false
            },
            //过滤数据
            async handleFilter() {
                this.meta.page = 1;
                this.bxLoading = true;
                const response = await getOrderList({
                    page: this.meta.current_page,
                    'order_sn': this.listQuery.order_sn
                });
                if (response) {
                    this.total = response.data.length;
                    this.ordersList = response.data;
                    this.bxLoading = false
                }
            },
            //编辑
            handleEdit(index, row) {
                this.dialogFormVisible = true;
                this.form = row;
                this.index = index;
            },
            // eslint-disable-next-line no-unused-vars
            handleSetFail(index, row) {
                this.$confirm(this.msg['m' + row.status], "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    this.failDialog = true;
                    this.fail.id = row.id;
                });
            },
            async updateToFail() {
                const rsp = await setOrderToFail({"id": this.fail.id, "fail_content": this.fail.failContent});
                if (rsp) {
                    let index = this.ordersList.findIndex(item => item.id === this.fail.id);
                    this.ordersList[index].status = -1;
                    this.ordersList[index].statuses = {'type': 'danger', 'string': '检测失败: ' + rsp.$order.fail_content};
                    this.fail.failContent = '';
                    this.fail.id = '';
                    this.failDialog = false;
                }
                console.log(rsp)
            },
            handleDelete(index, row) {
                this.$confirm(this.msg['m' + row.status], "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    // eslint-disable-next-line no-undef
                    const rsp = await deleteOrder('admin/orders/' + row.id, {"_method": 'DELETE'});
                    if (rsp.message === 'success') {
                        // console.log(index)
                        // this.$delete(this.papers, index) //之前新的直接删除现在要求保留改状态
                        const newItem = this.orders.filter(value => value.id === row.id)[0];
                        newItem.status = 9;
                        newItem.statuses = {'type': 'danger', 'string': '已删除'};
                        this.form[index] = newItem;
                        this.$message.success('删除成功');
                    }
                })
            },
            handleSelectionChange(val) {
                //选择
                this.multipleSelection = val.map(v => v.id);
            },
            //更改分页
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
            },

            async getOrderList(page = 1) {
                let response = await getOrderList({
                    page: page,
                    order_sn: this.listQuery.order_sn,
                    status: this.filterStatus
                });
                if (response) {
                    this.total = response.meta.total;
                    this.ordersList = response.data;
                    this.meta = response.meta;
                    this.bxLoading = false
                }
            },
            handleGetNextData() {

            },
            handleGetPrevData() {

            },
            handleSwitchPage(page) {
                // console.log(page)
                this.bxLoading = true;
                this.$route.query.page = page
                this.getOrderList(page)
            }
        },
        components: {
            edit,
            Pagination,
            interFaceButton
        }
    }
</script>
<style scoped>
    .el-table-column--selection .cell {
        text-align: center;
    }
    table{margin-bottom: 10px;}
    .filter-item {
        margin-right: 10px
    }

</style>
<style lang="stylus">

    .order-list
        background #ffffff
        padding 15px

        .tables
            width 100%
            display flex
            flex-shrink 0
            flex-wrap wrap

        .block
            display flex
            flex-wrap wrap
            justify-content center
            margin-top 15px
            width 100%

    .baogao
        display flex
        flex-wrap wrap

        .upload-demo
            width 100%
            display flex
            flex-wrap wrap
            flex-shrink 0

        .el-upload
            width 100%

        .el-upload-dragger
            width 100%

        .el-upload-list
            width 100%

    .download
        display block
        margin-bottom 15px

        span
            margin-right 15px
</style>
