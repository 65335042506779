<template>
    <el-dialog title="订单详情"
               :width="70+'%'"
               :close-on-click-modal="false"
               :before-close="cancleDiadLog"
               :visible.sync="dialogFormVisible">
        <el-form :model="form" v-if="form">
            <el-form-item label="订单号">
                <el-input v-model="form.order_sn" :readonly="true" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="当前状态" style="width: 100%;margin-top: 20px">
                <el-select
                        v-model="form.status"
                        placeholder="请选择"
                        :disabled="true"
                        style="width: 100%">
                    <el-option
                            v-for="item in status"
                            :key="item.value"
                            :label="item.status"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form-item label="论文标题">
                        <el-input v-model="form.title" :readonly="true" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="论文作者">
                        <el-input v-model="form.author" :readonly="true" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="论文字数">
                        <el-input v-model="form.words" :readonly="true" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="订单金额">
                        <el-input v-model="form.total_fee" :readonly="true" autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <template>
                <div v-if="form.submit_type === 'content'" class="content">
                    <el-form-item label="论文内容">
                        <el-input
                                type="textarea"
                                :rows="15"
                                v-model="form.content">
                        </el-input>
                    </el-form-item>
                </div>
                <div v-if="form.submit_type === 'file'" class="content ">
                    <el-form-item label="论文文件" style="width: 100%;margin-bottom: 5px">

                    </el-form-item>
                    <div class="download" v-if="form.file">
                        <span>{{form.file.former}}[{{form.file.wordsnum}}]</span>
                        <el-button type="primary"
                                   icon="el-icon-download"
                                   size="small"
                                   @click="handleDownloadFile" circle></el-button>
                    </div>
                </div>
            </template>

            <div class="baogao">
                <el-col :span="24">
                    <el-form-item label="论文报告" style="width: 100%" >
                        <el-upload
                                class="upload-demo"
                                drag
                                style="width: 100%"
                                accept=".zip,.rar,.7zip"
                                :limit="1"
                                :data="form"
                                :on-change="handleChangeUpload"
                                @dragover.prevent="dragOverHandle"
                                :on-error="handleUploaderror"
                                :on-success="handleSuccess"
                                :before-upload="beforeUpload"
                                :before-remove="beforeRemove"
                                :action="action"
                                :headers="headers"
                                :file-list="report"
                                :multiple="false">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text"  v-if="!reportLength">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__text"  v-else>已上传报告文件, 如需重新上传请删除在上传</div>
                            <div class="el-upload__tip" slot="tip">只能上传zip文件
                                <b>(如在设置中上传了全站秘籍,全站秘籍将会添加到压缩包内)</b></div>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </div>

        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancleDiadLog">取 消</el-button>
            <el-button type="primary" @click="cancleDiadLog">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import upload from '@/utils/upload'
    import {mapActions} from 'vuex'
    import { deleteOrderReport } from '@/api/order'
    import { mapGetters } from 'vuex'
    export default {
        name: "orderEdit",
        props:['dialogFormVisible', 'form', 'loading', 'status', 'action', 'index'],
        data(){
            return {

            }
        },
        created(){
        },
        computed:{
            ...mapGetters({
               'token' : 'auth/token'
            }),
            report:{
                get(){

                    if(typeof this.form.report !== "undefined" && this.form.report !== null){
                        return [this.form.report];
                    }
                    return [];
                },
                set(val){
                    this.form.report = val
                    return val
                }
            },
            reportLength:{
                get(){
                    return this.form.hasOwnProperty('report') && this.form.report !== null
                },
                set(){
                    return this.form.report !== null
                }
            },
            headers(){
                return {
                    'Authorization': 'Bearer '+this.token
                }
            }
        },
        methods:{
            ...mapActions({
                removeOrder: 'order/removeOrder'
            }),
            cancleDiadLog(){
              this.$emit('cancle-dialog')
            },
            //下载文件
            handleDownloadFile(){
                const file = process.env.VUE_APP_PAPER_FILE_URL+this.form.file.name;
                window.open(file, '_blank')
            },
            //删除之前
            beforeRemove(file){
                return this.$confirm(`确定移除 ${ file.name }？`).then(async () => {
                    const response =  await deleteOrderReport({
                        file: file.name,
                        id: this.form.id
                    });
                    if(response){
                        this.$emit('removeReport', this.form)
                    }
                    return true
                })
            },
            //上传完成
            handleSuccess(rsp){
                this.handleSetStatues(this.index, this.form);
                this.report = rsp.data.file;
                this.reportLength =  true;
                this.removeOrder(this.form);
            },
            handleSetStatues(index, row){
                this.$emit('setStatus', index, row);
                // this.form[index] = newItem
            },
            //改变文件上传方法
            // eslint-disable-next-line no-unused-vars
            handleChangeUpload(file, fileList){

            },
            //上传之前处理
            beforeUpload(file){
                if(!upload.handleCheckType(file)){
                    return false;
                }
            },
            //上传错误方法
            handleUploaderror(err){
                this.$message.error(err)
            },
        }
    }
</script>

<style scoped>
    .disabled{
        pointer-events: none;
        cursor: not-allowed;
    }
</style>