import {Message} from "element-ui";

const upload = {
    //checkType
    handleCheckType(file){
        let fileRule = 'application/zip,application/rar,application/7zip';
        if(!file){
            Message.error("上传文件只能是"+fileRule.split(',')+"格式!");
        }
        if(!fileRule.split(',').includes(file.type)){
            Message.error("上传文件只能是 zip ，rar 格式!");
            return false;
        }
        return true;
    }

}

export default upload