<template>
    <el-popconfirm
            title="此订单未提交到接口方,确定提交并提交到接口方吗？"
            size="mini"
            @onConfirm="onConfirm"
            style="float: left "
            :disabled="isDisabled"
            v-if="interface_order == null && status === 1 && group_interface_info != null"
    >
        <el-button :data-info="group_interface_info"
                   slot="reference"
                   type="warning"
                   size="mini"
                   :disabled="isDisabled" v-text="statusText.text">删除
        </el-button>
    </el-popconfirm>
    <el-button v-else-if="groupInterfaceInfo == null"
               type="danger"
               size="mini"
               v-text="'暂无接口'"
               :disabled="true"></el-button>
    <el-button v-else-if="status !== -1" :type="statusText.btn" size="mini" :disabled="isDisabled" v-text="statusText.text"
               @click="handleAction(statusText.type)"></el-button>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import {startCheck, submitInterfaceCheck} from '@/api/order';

    export default {
        name: "interfaceButton",
        props: ['interfaceOrder', 'status', 'oid', 'group_interface_info'],
        data() {
            return {
                groupInterfaceInfo: this.group_interface_info,
                statusState: {text: '提交接口', type: null, btn: 'primary'},

            }
        },
        watch: {
            group_interface_info(val) {
                this.groupInterfaceInfo = val
            }
        },
        computed: {
            statusCode(){
                return this.status
            },
            statusProp: {
                get() {
                    return this.statusCode
                },
                set(status) {
                    this.statusCode = status;
                }
            },
            type() {
                return 'primary'
            },
            interface_order: {
                get() {
                    return this.interfaceOrder;
                },
                set(val) {
                    this.order = val
                }
            },
            isDisabled() {
                return this.groupInterfaceInfo !== null &&
                    (this.interface_order !== null &&
                        ![1000, 1011].includes(this.interface_order.status_code || 0) ||
                        ![1, 9, -1].includes(this.statusProp)
                    )
            },
            statusText: {
                get() {
                    let status = this.statusState;
                    if (this.statusProp === 1) {
                        status.type = 'start'
                    }
                    if (this.interface_order !== null && this.interface_order.status_code === 1000) {
                        status.text = '开始检测';
                        status.type = 'start';
                        status.btn = 'primary'
                    }

                    if (this.statusProp !== 2 && this.interface_order !== null && this.interface_order.status_code === 1011) {
                        status.text = '检测中....';
                        status.type = '';
                        status.btn = 'primary'
                    }

                    if (this.statusProp === 2 && this.interface_order && this.interface_order.status_code === 1010) {
                        status.text = '检测完成';
                        status.type = '';
                        status.btn = 'success'
                    }

                    if (this.statusProp === -1 && this.interface_order && this.interface_order.status_code === 1012) {
                        status.text = '检测失败';
                        status.type = '';
                        status.btn = 'danger'
                    }

                    return status;
                },
                set(val) {
                    this.statusState = val
                }
            },
        },
        methods: {
            async onConfirm() {
                const response = await submitInterfaceCheck({
                    oid: this.oid,
                    group_interface_info: this.groupInterfaceInfo
                });
                console.log(response)
                if(response.status === 200){
                    let interface_order = Object.assign({}, this.interface_order, {status_code: 1011});
                    this.interface_order = interface_order;
                    this.statusText.text = "检测中....";
                    this.statusText.btn = "primary";
                    this.statusText.type = "";
                    this.statusProp = 100001;
                }
            },
            handleAction(type) {
                if (type === 'start') {
                    //开始检测
                    this.startCheck()
                }
            },
            async startCheck() {
                // eslint-disable-next-line no-unused-vars
                const response = await startCheck({
                    oid: this.interface_order.paper_order_id
                });
            }
        }
    }
</script>

<style scoped>

</style>