<template>
    <el-pagination
            class="finance-page"
            :layout="layout"
            background
            :hide-on-single-page="hiddenSingle"
            :page-size.sync="perSize"
            :page-count="meta ? meta.last_page : 0"
            :current-page="meta ? meta.current_page : 1"
            @current-change="handleChangeCurrent"
            :total="meta ? meta.total : 0">
    </el-pagination>
</template>

<script>
    export default {
        name: "Pagination",
        props:{
            layout:{
                required: true,
                type: String,
                default(){
                    return 'prev, pager, next'
                }
            },
            total:{
                default(){
                    return 0
                }
            },
            hiddenSingle:{
                default(){
                    return false
                }
            },
            //分页信息
            meta:{
                required: true,
            }
        },
        computed:{
          perSize(){
              return parseInt(this.meta?.per_page || {});
          }
        },
        methods:{
            handleChangeCurrent(curr){
                this.$emit('pagination:switched', curr)
            },

        }
    }
</script>

<style scoped>

</style>